import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Link from 'next/link';

// components
import { SplashScreen } from 'components/widgets';
import LayoutContainer from 'components/layout/LayoutContainer';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const logo = '/static/images/Asistensi_logotipo.svg';
class App extends Component {
  
  state = {
    loading: true,
    store: {},
  }
  
  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      <Component {...props} { ...this.props.query } />
    )} />
  )
  
  render() {
    const PublicRoute = this.PublicRoute;

    const NotFound = () => (
      <LayoutContainer>
        <div className="py-28 container flex justify-center items-center flex-col content">
        <img src={logo} alt="Logo venemed" className="w-auto h-24" loading="lazy" />
          <div className="flex justify-center flex-col text-center">
            <h1 className="font-bold text-6xl text-gray-800">404</h1>
            <p className="font-bold text-gray-800 text-base leading-relaxed mb-4">Lo sentimos, no se encontró la página que estas buscando.</p>
            <Link href="/">
              <div className="text-center flex justify-center items-center text-sm cursor-pointer text-gray-800" onClick={this.goToHome}>
                <KeyboardArrowLeftIcon/> Volver al inicio
              </div>
            </Link>
          </div>
        </div>
      </LayoutContainer>
    )

    if (this.state.loading === true) return <SplashScreen />;

    return (
      <Router>
        <div className="content">
          <div className="h-full">
            <Switch>
              <PublicRoute component={NotFound} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
